import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, Autoplay } from "swiper";
import Image from "next/image";

export default function HomeHero({ banners }) {
    const router = useRouter();
    const { handleSubmit, register } = useForm();

    const onSubmit = (data) => {
        if (data.txtFilter) router.query.filter = data.txtFilter;
        router.push(`/estoque?${new URLSearchParams(router.query)}`);
    }

    return (
        <>
            {
                banners.length > 0
                    ? <section className="dealership-hero position-relative overflow-hidden">
                        <Swiper
                            autoplay={true}
                            loop={true}
                            speed={1500}
                            effect={'fade'}
                            fadeEffect={{ crossFade: true }}
                            pagination={{ clickable: true }}
                            modules={[Pagination, EffectFade, Autoplay]}>
                            {
                                banners.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="dl-hero-single">
                                            <div className="at_hero_slider">
                                                <div className="at-hero-banner">
                                                    <Image
                                                        src={item.banner.imageUrl}
                                                        alt="banner"
                                                        width={1440}
                                                        height={420}
                                                        sizes="100vw"
                                                        priority={true}
                                                        style={{
                                                            width: '100%',
                                                            height: 'auto',
                                                            objectFit: 'cover'
                                                        }} />
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </section>
                    : null
            }
            <div className="at-search-box overflow-hidden">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="bg-white rounded iv_sidebar_widget" style={{ padding: '16px' }}>
                                <form className="ct-form-wrapper" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row g-4">
                                        <div className="col-lg-9">
                                            <div className="input-field">
                                                <input type="text" placeholder="Filtre por marca ou modelo" {...register("txtFilter")} />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 submit-btn align-self-end">
                                            <button style={{ width: '100%' }} className="btn btn-secondary btn-md" type="submit">Buscar Agora</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}