import Head from 'next/head'
import { fetchGetAdsByCompany, fetchGetBanners } from '../../services/api'
import HomeCallToAction from '../components/home/call-to-action'
import HomeHero from '../components/home/hero'
import HomeLastOffers from '../components/home/last-offers'
import HomeWhatOurCustomerSays from '../components/home/what-our-customer-says'
import Footer from '../components/layout/footer'
import Header from '../components/layout/header'

export default function HomePage({ companyGeneralData, adsLastOffers, banners }) {
  companyGeneralData = JSON.parse(companyGeneralData);

  return (
    <>

      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={`${(companyGeneralData.company.nickName).toUpperCase()}Revenda de veículos multimarcas, novos e semi-novos em ${companyGeneralData.company.city ? companyGeneralData.company.city : ''}. Veículos com procedência e garantia.`} />
        <meta name="author" content="https://bndv.com.br" />
        <title>{`${(companyGeneralData.company.nickName).toUpperCase()} - ${companyGeneralData.company.city ? companyGeneralData.company.city : ''} `}</title>
      </Head>

      <div className="main-wrapper">
        <Header company={companyGeneralData.company} />
        <HomeHero banners={banners} />
        <HomeLastOffers ads={adsLastOffers} />
        <HomeCallToAction />
        <HomeWhatOurCustomerSays />
        <Footer company={companyGeneralData.company} />
      </div>

    </>
  )

}

export async function getServerSideProps({ query, res }) {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59'
  );

  const adsLastOffers = await fetchGetAdsByCompany({
    pagination: { pageSize: 8, page: query.pag ?? 0 },
    filters: {featured: true},
    orderBy: 'LAST_VEHICLES'
  });
  const banners = await fetchGetBanners({ idMenuOption: 1 });

  return {
    props: {
      adsLastOffers,
      banners
    }
  }
}